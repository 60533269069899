import { donTomas } from '../brands';

export default [
  // {
  //   product: 'Bundle Honduras Toro 5U',
  //   module: 'Venda ao bundle',
  //   image: '/img/categories/charuto/donTomas/toro5u/product.png',
  //   barcode: '8720400404858',
  //   brand: donTomas,
  //   filters: {
  //     intensidade: 'medioForte',
  //     vitola: 'toro',
  //   },
  //   properties: [
  //       { key: "Comprimento", value: "15,2cm" },
  //       { key: "Diâmetro", value: "2,1cm" },
  //       { key: "Folha", value: "Colorado" },
  //       { key: "Sabor", value: "Forte e picante" },
  //   ],
  // },
  // {
  //   product: 'Bundle Honduras Rothschild 5U',
  //   module: 'Venda ao bundle',
  //   image: '/img/categories/charuto/donTomas/rothschild5u/product.png',
  //   barcode: '8720400404889',
  //   brand: donTomas,
  //   filters: {
  //     intensidade: 'medioForte',
  //     vitola: 'rothschild',
  //   },
  //   properties: [
  //       { key: "Comprimento", value: "11,4cm" },
  //       { key: "Diâmetro", value: "2cm" },
  //       { key: "Folha", value: "Colorado" },
  //       { key: "Sabor", value: "Forte e picante" },
  //   ],
  // },
  {
    product: 'Clásico Robusto 1U',
    module: 'Venda à unidade',
    image: '/img/categories/charuto/donTomas/robusto1u/product.png',
    barcode: '8720400449590',
    brand: donTomas,
    filters: {
      intensidade: 'medioForte',
      vitola: 'robusto',
    },
    properties: [
        { key: "Comprimento", value: "14cm" },
        { key: "Diâmetro", value: "2cm" },
        { key: "Folha", value: "Colorado" },
        { key: "Sabor", value: "Forte e picante" },
    ],
  },
  {
    product: 'Dominicana Robusto Bundle 10U',
    module: 'Venda ao bundle',
    image: '/img/categories/charuto/donTomas/dominicanaRobusto10u/product.png',
    barcode: '8720400681365',
    brand: donTomas,
    filters: {
      intensidade: 'suaveMedio',
      vitola: 'robusto',
    },
    properties: [
        { key: "Comprimento", value: "14cm" },
        { key: "Diâmetro", value: "1,98cm" },
        { key: "Folha", value: "Colorado" },
    ],
  },
  {
    product: 'Dominicana Bundle Toro 10U',
    module: 'Venda ao bundle',
    image: '/img/categories/charuto/donTomas/dominicanaToro10u/product.png',
    barcode: '8720400681341',
    brand: donTomas,
    filters: {
      intensidade: 'suaveMedio',
      vitola: 'toro',
    },
    properties: [
        { key: "Comprimento", value: "15,2cm" },
        { key: "Diâmetro", value: "1,65cm" },
        { key: "Folha", value: "Colorado" },
    ],
  },
];
