import { cigarrilhas, cachimbo, charuto, enrolar } from "./categories";

const signature = {
  slug: "signature",
  name: "Signature",
  category: cigarrilhas,
  description:
    "O <strong>sabor único e suave</strong> das <strong>cigarrilhas Signature</strong> é o resultado da <strong>seleção cuidadosa e da mistura de tabacos de qualidade</strong>, que fazem da marca Signature o <strong>número um mundial no prazer de fumar</strong>. Para a criação de uma cigarrilha de Café Crème seleccionamos e misturamos tabaco de Java, Colômbia e República Dominicana.",
  image: "/img/categories/cigarrilhas/signature/logo.png",
  slideGroup: 1,
  itemsPerPage: [6,7],
};

const signaturePlc = {
  slug: "signature-plc",
  name: "Signature PLC",
  category: cigarrilhas,
  description:
    "O <strong>Signature Filter Blue & Dual</strong> é uma solução de marca desenhada especificamente para consumidores de cigarros. É um <strong><i>american blend</i>, inalável</strong>, de tamanho e fumada similar a um cigarro.",
  image: "/img/categories/cigarrilhas/signature-plc/logo.png",
  slideGroup: 3,
  itemsPerPage: [2],
};

const laPaz = {
  slug: "laPaz",
  name: "La Paz",
  category: cigarrilhas,
  description:
    "<strong>La Paz</strong> é uma referência internacional e o líder no segmento de <strong>100% tabaco</strong>, oferecendo nas suas cigarrilhas e charutos <strong>um sabor pleno e uma qualidade autêntica. La Paz simboliza força, autenticidade, aventura e satisfação plena.</strong> Uma cigarrilha composta por 100% tabaco, <strong>sem aditivos, e com um final Wilde</strong> (ponta inacabada, para facilitar o acender da mesma).",
  image: "/img/categories/cigarrilhas/laPaz/logo.png",
  slideGroup: 4,
};

const mercator = {
  slug: "mercator",
  name: "Mercator",
  category: cigarrilhas,
  description:
    "Mercator é uma <strong>garantia de qualidade</strong> desde 1886, possuindo um <strong>sabor doce e requintado</strong> para o fumador com o paladar <strong>mais exigente</strong>.",
  image: "/img/categories/cigarrilhas/mercator/logo.png",
  slideGroup: 4,
};

const henriWintermans = {
  slug: "henriWintermans",
  name: "Henri Wintermans",
  category: cigarrilhas,
  description:
    "Com todo o seu sabor, <strong>Henri Wintermans</strong> é fumado habitualmente <strong>depois de uma boa refeição</strong> ou para <strong>relaxar em boa companhia</strong>.",
  image: "/img/categories/cigarrilhas/henriWintermans/logo.png",
  slideGroup: 4,
};

const panter = {
  slug: "panter",
  name: "Panter",
  category: cigarrilhas,
  description:
    "<strong>Panter</strong> é uma cigarrilha <strong>fácil de fumar e acessível</strong>. O seu sortido <i>core</i> apela a um público-alvo mais masculino, enquanto o aromático abrange o público-alvo feminino e ainda <i>dual-consumption</i>.",
  image: "/img/categories/cigarrilhas/panter/logo.png",
  slideGroup: 5,
};

const meharis = {
  slug: "meharis",
  name: "Mehari's",
  category: cigarrilhas,
  description:
    "A cigarrilha de cada <strong>Mehari’s</strong> é feita com tabacos da mais alta qualidade .A marca <strong>Mehari’s</strong> tem as suas raízes nas origens naturais do tabaco <strong>Mehari’s Java 10, Mehari’s Ecuador 10 e Mehari’s Red Orient 20</strong>. Consumidores atraídos por uma cigarrilha prática, numa embalagem marcante: Estes consumidores apreciam, não só o sabor na fumada, mas também o comprimento da cigarrilha.",
  image: "/img/categories/cigarrilhas/meharis/logo.png",
  slideGroup: 6,
};

const breakX = {
  slug: "break",
  name: "Break",
  category: cigarrilhas,
  description:
    "<strong>Break</strong> é uma marca cuidadosamente desenvolvida para <strong>proporcionar uma experiência similar a cigarros, com um filtro embutido</strong>, empacotados similarmente a cigarros. Com um <strong>portfolio muito completo</strong>, é uma <strong>marca capaz de suprimir as necessidades de grande parte dos consumidores</strong>.",
  image: "/img/categories/cigarrilhas/break/logo.png",
  slideGroup: 7,
};

const note = {
  slug: "note",
  name: "Note",
  category: cigarrilhas,
  description:
    "<strong>Note</strong> oferece-lhe <strong>a melhor relação qualidade - preço</strong>, com um design <strong>atual e moderno</strong>.",
  image: "/img/categories/cigarrilhas/note/logo.png",
  slideGroup: 8,
};

const borkumRiff = {
  slug: "borkumRiff",
  name: "Borkum Riff",
  category: cachimbo,
  description:
    "<strong>Aromas incríveis, sabor cativante e uma variedade digna de registo</strong>. Borkum Riff é uma das marcas mais vendidas e amplamente distribuídas de tabaco de cachimbo, estando disponível em mais de 50 países, e garantindo uma excelente qualidade para gostos requintados. ",
  image: "/img/categories/cachimbo/borkumRiff/logo.png",
};

const skandinavik = {
  slug: "skandinavik",
  name: "Skandinavik",
  category: cachimbo,
  description:
    "<strong>Skandinavik apresenta uma mistura aromática que transporta consigo classe e harmonia constantes</strong>. Com uma doce subtileza e um excelente aroma fresco, o contraste e o equilíbrio são as notas dominantes desta marca.",
  image: "/img/categories/cachimbo/skandinavik/logo.png",
};

const alsboBlack = {
  slug: "alsboBlack",
  name: "Alsbo Black",
  category: cachimbo,
  description:
    "<strong>Alsbo</strong> apresenta igualmente uma <strong>experiência única, extremamente leve, madura</strong> e que pode ser apreciada o dia inteiro.",
  image: "/img/categories/cachimbo/alsboBlack/logo.png",
};

const sail = {
  slug: "sail",
  name: "Sail",
  category: cachimbo,
  description:
    "Através de uma <strong>mistura bem equilibrada</strong> e em perfeita harmonia, <strong>Sail proporciona um aroma suave, doce e agradável</strong>.",
  image: "/img/categories/cachimbo/sail/logo.png",
};

const clan = {
  slug: "clan",
  name: "Clan",
  category: cachimbo,
  description:
    "<strong>Clan oferece uma experiência notável para qualquer fumador, através do seu aroma e frescura assinaláveis</strong>. Este tabaco de cachimbo cria uma mistura de alta qualidade e um equilíbrio brilhante.",
  image: "/img/categories/cachimbo/clan/logo.png",
};

const hollandHouse = {
  slug: "hollandHouse",
  name: "Holland House",
  category: cachimbo,
  description:
    "<strong>Nascido na Holanda, proporciona um fumar equilibrado e suave</strong>. Os sabores produzidos pelos Holland House são mais suaves, doces e destacam-se pela experiência enriquecedora que é fumar um destes tabacos de cachimbo.",
  image: "/img/categories/cachimbo/hollandHouse/logo.png",
};

const macanudo = {
  slug: "macanudo",
  name: "Macanudo",
  category: charuto,
  description:
    "<strong>Os Mestres Misturadores e Artesãos de Macanudo provaram as suas competências: Macanudo Inspirado</strong> é um charuto enrolado à mão, composto por tabacos das Honduras, Nicarágua e Republica Dominicana, juntos por uma sub-capa de Jamastran Valley nas Honduras. <strong>Todo este orgulho é enrolado numa rica e humidificada capa de folha Rosada</strong>, de San Agustin, nas Honduras.",
  image: "/img/categories/charuto/macanudo/logo.png",
};

const cao = {
  slug: "cao",
  name: "Cao",
  category: charuto,
  description:
    "A equipa de mistura dedicada a <strong>Cao Pilón</strong> aplicou uma <strong>exclusiva técnica de fermentação</strong> ao seu <strong>suave tabaco</strong> que compõe a capa do charuto (proveninente do Equador - com semente Habano), e desenvolveu esta mistura com uma <strong>tripa e sub-capa compostas por picantes folhas de tabaco do Nicarágua</strong>, advindas das distintas regiões de Estelí e Ometepe. O resultado é uma <strong>colecção de charutos bem balanceados, cuja cor rica e sagaz se alia a uma profunda intensidade de sabor</strong>, que junta notas de amadeirado, picante, e um toque de adocicado.",
  image: "/img/categories/charuto/cao/logo.png",
};

const donTomas = {
  slug: "donTomas",
  name: "Don Tomás",
  category: charuto,
  description:
    "Os elegantes charutos <strong>Don Tomás</strong> são produzidos de forma artesanal, seguindo uma <strong>receita tradicional</strong> que remonta a 1970, proveniente de Danli, nas Honduras. Ganharam ainda a sua reputação de <strong>uma das melhores marcas de charutos premium do Mundo</strong> devido aos seus <strong>elevados padrões de qualidade</strong> combinados com os seus <strong>preços extremamente competitivos</strong>. Consistem ainda numa mistura de <strong>tabacos bem maturados, ricos, e com uma fragrância bem distinta</strong>, proveniente da América Central, que proporciona uma sensação de prazer constante durante toda a fumada.",
  image: "/img/categories/charuto/donTomas/logo.png",
};

const reposado = {
  slug: "reposado",
  name: "Reposado 96",
  category: charuto,
  description:
    "Produzidos artesanalmente com <strong>folhas de tabaco envelhecidas e enroladas à mão</strong>, os charutos <strong>Reposado Connecticut</strong> advêm do Nicarágua, e são compostos por <strong>tabacos ricos de semente Cubana</strong>. Misturado apenas com tabaco Dominicano, <strong>Reposado Connecticut proporciona uma experiência consistente e límpida no paladar, juntamente com um sabor rico e eminente</strong>. Oferece ainda um bouquet cremoso com notas de café, que complementam o seu sabor na perfeição.",
  image: "/img/categories/charuto/reposado/logo.png",
};

const florDelSol = {
  slug: "florDelSol",
  name: "Flor del Sol",
  category: charuto,
  description:
    "<strong>Flor del Sol</strong> é um charuto medium filler enrolado à mão que contém <strong>tabaco apenas proveniente das Honduras</strong>. O envólucro em celofane, juntamente com <strong>o seu aspecto natural</strong>, conferem à embalagem <strong>a possibilidade de obter o visual e textura do produto</strong>.",
  image: "/img/categories/charuto/florDelSol/logo.png",
};

const laEstrella = {
  slug: "laEstrella",
  name: "La Estrella",
  category: charuto,
  description:
    "<strong>La Estrella Polar</strong>, cujo nome advém da icónica <strong>estrela polar</strong>, é constituído por <strong>tabacos de alta qualidade</strong>, e foi criado com o retalhista dos charutos e os seus clientes em mente. É um charuto <strong>muito competitivo em termos de preço</strong>, artesanal, e cuja marca <strong>é inspirada pela herança cubana</strong>. Este charuto oferece uma mistura distincta, com tabacos da Colômbia e da Nicarágua que constituem a tripa, com uma sub-capa da Indonésia, e acabado ainda com uma capa do Equador.</br><strong>La Estrella oferece uma alternativa suave a um preço muito competitivo</strong>.",
  image: "/img/categories/charuto/laEstrella/logo.png",
};

const crossRoad = {
  slug: "crossRoad",
  name: "Cross Road",
  category: enrolar,
  description:
    "Com o tabaco de enrolar Crossroad oferecemos uma <strong>qualidade diferente de outros tabacos naturais: Conseguimos criar um processo único para manter o teor de humidade do tabaco, usando unicamente aditivos naturais para manter a frescura e o nível ideal de humidade por mais tempo</strong>.",
  image: "/img/categories/enrolar/crossRoad/logo.png",
};

const list = [
  signature,
  laPaz,
  // panter,
  mercator,
  // henriWintermans,
  // signaturePlc,
  // meharis,
  breakX,
  // note,
  borkumRiff,
  skandinavik,
  alsboBlack,
  sail,
  clan,
  hollandHouse,
  macanudo,
  cao,
  donTomas,
  // laEstrella,
  reposado,
  florDelSol,
  crossRoad,
];

export {
  signature,
  signaturePlc,
  laPaz,
  mercator,
  henriWintermans,
  panter,
  // meharis,
  breakX,
  // note,
  borkumRiff,
  skandinavik,
  alsboBlack,
  sail,
  clan,
  hollandHouse,
  macanudo,
  cao,
  donTomas,
  reposado,
  florDelSol,
  laEstrella,
  crossRoad,
  list,
};
