import { reposado } from '../brands';

export default [
  {
    product: 'Connecticut Robusto 10U',
    module: 'Venda ao bundle',
    image: '/img/categories/charuto/reposado/robusto10u/product.png',
    barcode: '8720400433803',
    brand: reposado,
    filters: {
      intensidade: 'medio',
      vitola: 'robusto',
    },
    properties: [
        { key: "Comprimento", value: "12,7cm" },
        { key: "Diâmetro", value: "2cm" },
        { key: "Folha", value: "Colorado" },
        { key: "Sabor", value: "Forte e picante" },
    ],
  },
  {
    product: 'Connecticut Toro 10U',
    module: 'Venda ao bundle',
    image: '/img/categories/charuto/reposado/toro10u/product.png',
    barcode: '8720400433810',
    brand: reposado,
    filters: {
      intensidade: 'medio',
      vitola: 'toro',
    },
    properties: [
        { key: "Comprimento", value: "15,2cm" },
        { key: "Diâmetro", value: "2cm" },
        { key: "Folha", value: "Colorado" },
        { key: "Sabor", value: "Forte e picante" },
    ],
  },
];
