import { signature } from "../brands";

export default [
  {
    product: "Original 20",
    module: "Módulo: 5 Unidades",
    image: "/img/categories/cigarrilhas/signature/original20/product.png",
    barcode: "8720400309993",
    brand: signature,
    filters: {
      tamanho: "regular",
      sabor: "original",
      filtro: "semFiltro",
    },
    properties: [],
  },
  {
    product: "Original 10",
    module: "Módulo: 10 Unidades",
    image: "/img/categories/cigarrilhas/signature/original10/product.png",
    barcode: "8720400308279",
    brand: signature,
    filters: {
      tamanho: "regular",
      sabor: "original",
      filtro: "semFiltro",
    },
    properties: [],
  },
  {
    product: "Blue 20",
    module: "Módulo: 5 Unidades",
    image: "/img/categories/cigarrilhas/signature/blue20/product.png",
    barcode: "8720400460106",
    brand: signature,
    filters: {
      tamanho: "regular",
      sabor: "suave",
      filtro: "semFiltro",
    },
    properties: [],
  },
  {
    product: "Blue 10",
    module: "Módulo: 10 Unidades",
    image: "/img/categories/cigarrilhas/signature/blue10/product.png",
    barcode: "8720400308293",
    brand: signature,
    filters: {
      tamanho: "regular",
      sabor: "suave",
      filtro: "semFiltro",
    },
    properties: [],
  },
  {
    product: "Red 10",
    module: "Módulo: 10 Unidades",
    image: "/img/categories/cigarrilhas/signature/red10/product.png",
    barcode: "8720400429752",
    brand: signature,
    filters: {
      tamanho: "regular",
      sabor: "aromatico",
      filtro: "semFiltro",
    },
    properties: [],
  },
  {
    product: "Filter Red 10",
    module: "Módulo: 10 Unidades",
    image: "/img/categories/cigarrilhas/signature/filterRed10/product.png",
    barcode: "8720400424740",
    brand: signature,
    filters: {
      tamanho: "regular",
      sabor: "aromatico",
      filtro: "comFiltro",
    },
    properties: [],
  },
  {
    product: "Mini Filter Red 10",
    module: "Módulo: 10 Unidades",
    image: "/img/categories/cigarrilhas/signature/miniFilterRed10/product.png",
    barcode: "8720400428328",
    brand: signature,
    filters: {
      tamanho: "mini",
      sabor: "aromatico",
      filtro: "comFiltro",
    },
    properties: [],
  },
  {
    product: "Mini Filter Blue 10",
    module: "Módulo: 10 Unidades",
    image: "/img/categories/cigarrilhas/signature/miniFilterBlue10/product.png",
    barcode: "8720400434404",
    brand: signature,
    filters: {
      tamanho: "mini",
      sabor: "suave",
      filtro: "comFiltro",
    },
    properties: [],
  },
  {
    product: "Mini Filter Green 10",
    module: "Módulo: 10 Unidades",
    image: "/img/categories/cigarrilhas/signature/miniFilterGreen10/product.png",
    barcode: "8720400527021",
    brand: signature,
    filters: {
      tamanho: "mini",
      sabor: "aromatico",
      filtro: "comFiltro",
    },
    properties: [],
  },
  {
    product: "Mini Filter Beige 20",
    module: "Módulo: 5 Unidades",
    image: "/img/categories/cigarrilhas/signature/miniFilterBeige20/product.png",
    barcode: "8720400670727",
    brand: signature,
    filters: {
      tamanho: "mini",
      sabor: "aromatico",
      filtro: "comFiltro",
    },
    properties: [],
  },
  {
    product: "Mini Original 20",
    module: "Módulo: 5 Unidades",
    image: "/img/categories/cigarrilhas/signature/miniOriginal20/product.png",
    barcode: "8720400488629",
    brand: signature,
    filters: {
      tamanho: "mini",
      sabor: "original",
      filtro: "semFiltro",
    },
    properties: [],
  },
  {
    product: "Mini Red 20",
    module: "Módulo: 5 Unidades",
    image: "/img/categories/cigarrilhas/signature/miniRed20/product.png",
    barcode: "8720400488599",
    brand: signature,
    filters: {
      tamanho: "mini",
      sabor: "aromatico",
      filtro: "semFiltro",
    },
    properties: [],
  },
  {
    product: "Mini Beige 20",
    module: "Módulo: 5 Unidades",
    image: "/img/categories/cigarrilhas/signature/miniBeige20/product.png",
    barcode: "8720400527052",
    brand: signature,
    filters: {
      tamanho: "mini",
      sabor: "aromatico",
      filtro: "semFiltro",
    },
    properties: [],
  },
];
